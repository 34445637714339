/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
:root {
    --hero-width: 100%;
    --hero-height: calc(100vh - 10rem);
}

.hero {
    width: var(--hero-width) !important;
    height: var(--hero-height) !important;
    position: relative;

    .hero-slider {
        width: var(--hero-width) !important;
        height: var(--hero-height) !important;

        .swiper-wrapper {
            width: var(--hero-width) !important;
            height: var(--hero-height) !important;

            .swiper-slide {
                width: var(--hero-width) !important;
                height: var(--hero-height) !important;
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;

                .hero-wrap {
                    width: var(--hero-width) !important;
                    height: var(--hero-height) !important;
                    .hero-item {
                        width: var(--hero-width) !important;
                        height: var(--hero-height) !important;
                        position: relative;
                        display: inline-block;
                        @media (max-width: 992px) {
                            height: 25rem;
                        }

                        .hero-img {
                            display: block;
                            width: var(--hero-width) !important;
                            height: var(--hero-height) !important;
                            object-position: center;
                            -o-object-position: center;
                            object-fit: cover !important;
                            -o-object-fit: cover !important;
                            @media (max-width: 992px) {
                                height: 25rem;
                            }
                        }

                        .hero-text {
                            background-color: rgba(0, 0, 0, .4);
                            // background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 10%);
                            padding: 20px;
                            margin-bottom: 7%;
                            width: 50%;
                            text-align: center;

                            -webkit-box-shadow: 0px 0px 30px 13px rgba(255,255,255,0.38);
                            -moz-box-shadow: 0px 0px 30px 13px rgba(255,255,255,0.38);
                            box-shadow: 0px 0px 30px 13px rgba(255,255,255,0.38);

                            h2 {
                                color: var(--oksijen-turuncu);
                                @media (max-width: 992px) {
                                    font-size: 14px !important;
                                }
                            }

                            h3 {
                                color: var(--bs-white);
                                @media (max-width: 992px) {
                                    font-size: 14px !important;
                                }
                            }
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            height: 100%;
                            width: 100%;
                            top: 0;
                            left: 0;
                            // background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 10%);
                        }
                    }
                }
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            color: var(--oksijen-turuncu);
        }

        .swiper-pagination {
            .swiper-pagination-bullet {
                background: var(--oksijen-turuncu);
            }
        }
    }
}
